<template>
    <v-container fluid>
     
      <v-tabs
        v-model="tab"
        dark
        class="pl-3 pr-3"
        @change="haddleTabClick"
      >
        <v-tabs-slider></v-tabs-slider>
        
        <v-tab href="#tab-0">
          Colours
        </v-tab>
  
        <v-tab href="#tab-1">
          Chargeback Search
        </v-tab>
  
      </v-tabs>
  
      <v-tabs-items v-model="tab" dark>
        
        <v-tab-item
          :value="'tab-0'"
        >
          <v-card flat style="background-color: #272b30;">
            <colours/>
          </v-card>
        </v-tab-item>
  
        <v-tab-item
          :value="'tab-1'"
        >
          <v-card flat style="background-color: #272b30;">
            <charge-back-search/>
          </v-card>
        </v-tab-item>
  
      </v-tabs-items>
    </v-container>
  </template>
  <script>
    import Colours from '../AdminManagement/Colours/Colours.vue'
    import ChargeBackSearch from '../AdminManagement/ChargebackSearch/chargebackSearch.vue'
    export default {
      components: {
        Colours,
        ChargeBackSearch
        // eventTags
      },
      data () {
        return {
          tab: null
        }
      },
      created() {
        this.checkSelectedTab();
      },
      methods: {
        haddleTabClick: function(val) {
          localStorage.setItem('setSelectedTab', JSON.stringify(val))
    
        },
        checkSelectedTab: function() {
          let selectedName = JSON.parse(localStorage.getItem('setSelectedTab'));
          if (selectedName && selectedName !== null) {
            this.tab = selectedName;
          }
        }
      }
    }
  //   export default {
  //     name: 'Home'
  //   }
  
  </script>
  
  <style scoped>
  
  body {
      background-color: #272b30;
  }
  h1{
    color: #a09c94;
    font-family: sans-serif;
  }
  p{
    color: #6e6e6e;
    font-family: sans-serif;
  }
  </style>